// import "@/assets/iconfont/iconfont.css";
import {createApp} from 'vue'
import App from './App.vue'
import Store from '@/store'
import Router from '@/router'
import { Field, Icon, Loading, Image as VanImage, Lazyload, Swipe, 
  SwipeItem, Search, Locale, Picker, Popup, ConfigProvider,Grid, GridItem,Divider,
Row,Col } from 'vant';
import 'vant/lib/index.css';

// import '@/assets/scss/global.scss'
// 引入英文语言包
import enUS from 'vant/es/locale/lang/en-US';
import { Pagination } from '@nutui/nutui'
import "@nutui/nutui/dist/style.css";
Locale.use('en-US', enUS);

createApp(App)
  // .use(Row)
  // .use(Col)
  .use(Divider)
  .use(GridItem)
  .use(Grid)
  .use(ConfigProvider)
  .use(Popup)
  .use(Picker)
  .use(Search)
  .use(Swipe)
  .use(SwipeItem)
  .use(Lazyload)
  .use(VanImage)
  .use(Loading)
  .use(Icon)
  .use(Pagination)
  .use(Field)
  .use(Store)
  .use(Router)
  .mount('#app')



