<template>
  <router-view></router-view>
</template>

<script>
  export default {
    name: "index",
    components: {},
    data() {},
    watch: {},
    computed: {},
    methods: {},
    mounted() {},
  }
</script>
<style lang="scss">
  @font-face {
    font-family: DIN;
    src: url('./assets/font/DIN.otf');
  }
  :root{}
  #app {
    font-family: PingFangSC-Medium, PingFang SC, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #020304;
  }
  body{
    background: #020304;
  }
  .pc-hide{
    display: none!important;
  }
  @media screen and (max-width: 750px) {
    .h5-hide{
      display: none;
    }
    .pc-hide{
      display: block;
    }
  }
</style>
<style scoped lang="scss">
  @media screen and (max-width: 750px) {
    body{
      background: #0e141d;
    }
    #app {
      background-color: #0e141d;
    }
  }
</style>
<style lang="scss">
  .ads{
    .ads-item{
      height: 126px!important;
    }
  }
  @media screen and (min-width: 1280px) and (max-width: 1600px) {
    .ads{
      .ads-item{
        height: 103px!important;
      }
    }
  }
  @media screen and (min-width: 750px) and (max-width: 1280px) {
    .ads{
      .ads-item{
        height: 58px!important;
      }
    }
  }
  @media screen and (max-width: 750px) {
    .ads{
      .ads-item{
        height: calc((100vw - 28px) / 3)!important;
      }
    }
  }
</style>
